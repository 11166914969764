import React, { FunctionComponent, useContext, useEffect } from "react";
import { ImageInterface } from "../../types/SanityTypes";
import SliderBlock from "../SliderBlock";
import "swiper/scss";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { event11 } from "../../analytics/event11";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatingsAndReviewScript from "../ProductRatingsAndReviewsScript";
import { Link } from "../Link";
import { createThemeClassName } from "../../utils/themeClass";

export interface ProductSliderV2Interface {
  heading?: string;
  bgColor?: {
    value: string;
  };
  template?: string;
  content: {
    _id: string;
    _rawImages: ImageInterface[];
    title: string;
    url: string;
    _rawImage: ImageInterface;
    SubBrand: {
      name: string;
    };
    variant?: {
      primaryColor?: {
        hex?: string;
      };
    };
    name: string;
    displayReviews: boolean;
    slug: {
      current: string;
    };
    paUPC: string;
    paSmartProductId: string;
    // For Preview
    images?: ImageInterface[];
  }[];
  isPopularProducts?: boolean;
  showBuyItNowButton?: boolean;
  theme?: {
    name: string;
  };
  ctaLink?: any;
  ctaLabel?: string;
}

const ProductSliderV2: FunctionComponent<ProductSliderV2Interface> = ({
  heading,
  bgColor,
  content,
  isPopularProducts = false,
  showBuyItNowButton = false,
  template = "productV2",
  ctaLabel,
  ctaLink,
  theme
}) => {
  const { sanitySiteSettings, sanityProductLandingPageV2 } = useContext(LocalizedContext);

  useEffect(() => {
    event11(content, sanitySiteSettings?.brandName, "Product Slider");
  }, [content, sanitySiteSettings?.brandName]);

  const getCtaURL = () => {
    if (ctaLink?.__typename === "SanityProductLinePageV2") {
      return `/${sanityProductLandingPageV2?.slug.current}/${ctaLink.slug?.current}/`;
    } else {
      return `/${ctaLink?.slug?.current}/`;
    }
  };

  return (
    <section
      className={`${isPopularProducts ? "popular-products-slider product-slider" : "product-slider"} ${
        theme && createThemeClassName(theme?.name)
      }`}
      data-testid="product-slider"
    >
      <Container fluid>
        {heading && (
          <h2 className={`${template === "productV2" ? "rx-heading--medium" : ""}`} style={{ color: bgColor?.value }}>
            {theme?.name ? <span>{heading.toUpperCase()}</span> : heading.toUpperCase()}
          </h2>
        )}
        {content && (
          <SliderBlock
            data={content as ProductSliderV2Interface["content"]}
            isPopularProducts={isPopularProducts}
            showBuyItNowButton={showBuyItNowButton}
            type={template}
          />
        )}

        {ctaLink && (
          <Link
            className="rx-product-slider-v2--cta"
            _id={""}
            to={`${getCtaURL() || "/products/"}`}
            data-analytics-event4=""
          >
            {ctaLabel}
          </Link>
        )}
      </Container>
      <ProductRatingsAndReviewScript />
    </section>
  );
};

export default ProductSliderV2;
